///* ----------------------------------------------------------------------------------------------------- */
///*  @ Import/write your custom styles here.
///*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
///* ----------------------------------------------------------------------------------------------------- */
//
//
//@tailwind base;
//@tailwind components;
//@tailwind utilities;
//
///* You can add global styles to this file, and also import other style files */
//$font-family-nt: "Inter var", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//html, body {
//    height: 100%;
//}
//
//body {
//    margin: 0;
//    font-family: Roboto, "Helvetica Neue", sans-serif;
//}
//
//// CUSTOM MATERIAL COMPONENTS STYLES
//@import "assets/styles/am-table";
//@import "assets/styles/am-button";
//@import "assets/styles/am-form-field";
//@import "assets/styles/am-dialog";
//@import "assets/styles/am-menu";
//@import "assets/styles/am-card";
//@import "assets/styles/am-chip";
//@import "assets/styles/am-tooltip";
//@import "assets/styles/am-tab";
//@import "assets/styles/am-snack-bar";
//@import "assets/styles/am-stepper";
//
//// FUENTES
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Pontano+Sans&display=swap');
//
///* Helpers */
//.border-dashed {
//    border: 1px dashed gray !important;
//}
//
//.hr-spacer{
//    border: 1px solid rgba(226,232,240,1) !important;
//    &-short{
//        width: 50px !important;
//        margin: 0;
//    }
//}
//
//.br-1 {
//    border-radius: 10px !important;
//}
//
.truncate-small {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8125rem;
    color: rgb(100,116,139);
}
//
//.gap-1 {
//    gap: 10px;
//}
//
//.grey-text {
//    color: rgba(128, 128, 128, 0.752) !important;
//}
//
//.monserrat {
//    font-family: Montserrat, sans-serif !important;
//}
//
//.pontano {
//    font-family: "Pontano Sans", sans-serif !important;
//}
//
//.dashed-container{
//    border: 1px dashed rgba(112,112,112,0.25);
//    border-radius: 5px;
//    padding-top: 8px;
//    padding-bottom: 8px;
//}
//
//.standart-icon{
//    max-height: 15px!important;
//    max-width: 15px!important;
//    min-height: 15px!important;
//    min-width: 15px!important;
//}
//
//.content-padding{
//    padding: 32px;
//}
//
//.mat-mdc-icon-button{
//    color: rgb(100, 116, 139);
//    &:hover{
//        background-color: rgba(148,163,184,0.12);
//    }
//    &-selected{
//        background-color: rgba(148,163,184,0.12) !important;
//        height: 2rem !important;
//        width: 2rem !important;
//        font-size: 1.25rem !important;
//        line-height: 1rem !important;
//        padding: 0 !important;
//        .mat-icon{
//            font-size: 1.25rem;
//            color: rgb(100, 116, 139);
//            height: 1.25rem !important;
//            width: 1.25rem !important;
//
//        }
//    }
//
//    &-small{
//        height: 1.75rem !important;
//        width: 1.75rem !important;
//        font-size: 0.75rem !important;
//        line-height: 0.75rem !important;
//        padding: 0 !important;
//        svg{
//            height: 1rem !important;
//            width: 1rem !important;
//        }
//        .mat-icon{
//            font-size: 1rem;
//            height: 1rem !important;
//            width: 1rem !important;
//
//            &-primary{
//                font-size: 0.625em;
//                line-height: 2.5em;
//                color: #2196f3;
//            }
//        }
//    }
//}
//
//.mat-badge{
//    .mat-badge-content{
//        font-size: 14px;
//        color: white;
//    }
//}
//
//.mat-icon{
//    &-selected{
//        font-size: 1.25rem;
//        color: rgb(100, 116, 139);
//        height: 1.25rem !important;
//        width: 1.25rem !important;
//    }
//    &-grey{
//        color: #64748b !important;
//    }
//    &-success{
//        color: #7ECC79 !important;
//    }
//    &-error{
//        color: #DB4035 !important;
//    }
//    &-warning{
//        color: #FFA500 !important;
//    }
//    &-info{
//        color: #4073FF !important;
//    }
//
//    &-big{
//        font-size: 100px;
//        height: 100px !important;
//        width: 100px !important;
//    }
//}
//
///* width */
//::-webkit-scrollbar {
//    width: 10px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//    background: #f1f1f1;
//    border-radius: 15px;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//    background: #888;
//    border-radius: 15px;
//}
//
///* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//    background: #555;
//    border-radius: 15px;
//}
//
//.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
//    border-right-style: hidden;
//}
